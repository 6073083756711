<template>
  <div>
    <el-drawer :title="Title" @close="$emit('closeDrawer')" :visible.sync="drawer" :closable="false" :wrapperClosable="false">
      <div>
        <el-alert show-icon class="hint-title" :title="AlertTitle" :closable="false" type="warning"> </el-alert>
        <div class="device" v-if="DeviceData">
          <el-scrollbar>
            <el-card class="box-card">
              <div class="device_table" v-for="o in DeviceData" :key="o.id">
                <img :src="baseip + o.iconUrl" alt="" style="height: 100px" />
                <div class="deviceName">{{ o.name }}</div>
              </div>
            </el-card>
          </el-scrollbar>
        </div>
        <div class="drawer_from" v-if="NestGroupData">
          <el-alert class="drawer-title" title="引用嵌套组" type="info" :closable="false"> </el-alert>
          <el-table :data="NestGroupData" height="300" border style="width: 100%">
            <el-table-column type="index" label="序号" width="50"> </el-table-column>
            <el-table-column prop="code" label="参数组代码" width="180"> </el-table-column>
            <el-table-column prop="name" label="组名称"> </el-table-column>
          </el-table>
        </div>
        <div class="drawer_from" v-if="TemplateData">
          <el-alert class="drawer-title" title="引用设备模板" type="info" :closable="false"> </el-alert>
          <el-table :data="TemplateData" height="300" border style="width: 100%">
            <el-table-column type="index" label="序号" width="50"> </el-table-column>
            <el-table-column prop="realName" label="负责人" width="180"> </el-table-column>
            <el-table-column prop="name" label="模板名称"> </el-table-column>
          </el-table>
        </div>

        <div class="button_group" v-if="isBtnGroup">
          <el-button @click="$emit('closeDrawer')">取消</el-button>
          <el-button type="primary" @click="$emit('clickbtn')">{{ isdel ? '确定删除' : '保存' }}</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
/**
 * 抽屉组件
 * 接收数据
 * @title           抽屉标题
 * @AlertTitle      提示文字
 * @devicedata      设备引用数据
 * @TemplateData    模板数据
 * @NestGroupData   嵌套组数据
 * @isdel           删除预览
 * @isBtnGroup      按钮展示
 * 可监听事件
 * @clickbtn            保存事件-无接收数据
 * @closeDrawer         取消事件-关闭drawer
 */
//  @click="drawer = true"
export default {
  name: 'drawer',
  data() {
    return {
      btn: '',
      baseip: ''
    };
  },
  props: {
    drawer: {
      type: Boolean,
      require: true
    },
    isBtnGroup: {
      type: Boolean,
      default: true
    },
    isdel: {
      type: Boolean,
      require: true
    },
    Title: {
      type: String,
      require: true
    },
    AlertTitle: {
      type: String,
      require: true
    },
    BtnInfo: {
      type: String,
      require: true
    },
    DeviceData: {
      type: Array
    },
    TemplateData: {
      type: Array
    },
    NestGroupData: {
      type: Array
    },
    staticIcon: Boolean
  },
  created() {
    this.baseip = this.staticIcon ? process.env.VUE_APP_AESSETS_URL : process.env.VUE_APP_AESSETS_URL;
  },
  methods: {}
};
</script>

<style scoped lang="scss">
/deep/.el-drawer__body {
  padding: 0 20px;
}
.el-alert {
  width: 100%;
}
.box-card {
  width: 100%;
  height: calc(100vh * 0.8);
  margin-top: 20px;
}
/deep/.el-card__body {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}
.device_table {
  width: 24%;
  height: 140px;
  box-sizing: border-box;
  margin-right: 10px;
  padding: 10px;
  display: flex;
  border: 1px solid #f3f3f3;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}
/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}
.deviceName {
  text-align: center;
  width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-scrollbar {
  height: calc(100vh * 0.8);
}

/deep/ .drawer_from .drawer-title .el-alert__content .el-alert__title {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.712);
}
/deep/.drawer-title {
  margin-bottom: 10px;
}
/deep/.el-table {
  margin-bottom: 10px;
}
.hint-title {
  margin-bottom: 10px;
}
.button_group {
  margin-top: 10px;
  float: right;
}
img {
  width: 100%;
  height: 90%;
}
.el-drawer__header {
  font-size: 20px;
}
</style>
