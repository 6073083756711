<template>
  <div class="basicParams">
    <el-row>
      <el-col :span="2"> </el-col>
      <el-button class="iconfont icon-dcicon_save1" type="primary" @click="isSave ? save('form') : edit('form')">保存</el-button>
      <el-button class="iconfont icon-dcx" @click="quit()">取消</el-button>
    </el-row>
    <el-form ref="form" :model="form" label-width="100px">
      <baseSection name="参数组信息">
        <el-row>
          <el-col :offset="0" :span="7">
            <el-form-item label="参数组名称:" prop="name" :rules="[{ required: true, message: '请输入参数名称', trigger: 'blur' }]">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="7">
            <el-form-item label="参数组代码:" prop="code" :rules="[{ required: true, message: '请输入参数代码', trigger: 'blur' }]">
              <el-input v-model="form.code" placeholder="请输入参数代码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </baseSection>
      <baseSection name="上传资料">
        <el-row>
          <el-col :offset="0" :span="7">
            <el-form-item label="参数组图片">
              <Upload
                :setimgUrl="form.iconUrl"
                :currenttype="5"
                @getimgUrl="
                  item => {
                    form.iconUrl = item;
                  }
                "
                @geticonId="
                  item => {
                    form.iconId = item;
                  }
                "
              ></Upload>
            </el-form-item>
          </el-col>
        </el-row>
      </baseSection>
    </el-form>
    <Drawer
      v-if="drawer"
      :drawer="drawer"
      @closeDrawer="closeDrawer"
      :title="'确认修改'"
      :AlertTitle="'修改内容将同步到以下设备模板和参数组'"
      :TemplateData="TemplateData.length ? TemplateData : null"
      :NestGroupData="NestGroupData.length ? NestGroupData : null"
      @clickbtn="update()"
    />
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
import Drawer from '@/components/drawer/index.vue';
import Upload from '@/components/upload/uploadimg/index.vue';
export default {
  components: {
    Upload,
    Drawer,
    baseSection
  },
  data() {
    return {
      drawer: false,
      isSave: false,
      form: {
        name: '',
        code: '',
        iconId: '',
        iconUrl: ''
      },
      TemplateData: [],
      NestGroupData: []
    };
  },
  watch: {
    searchText() {
      this.loadData();
    }
  },
  mounted() {
    if (this.$route.query.data) {
      this.isSave = false;
      this.form = this.$route.query.data;
    } else {
      this.isSave = true;
    }
    if (this.$route.query.data === '[object Object]') {
      this.$router.go(-1);
      return false;
    }
  },
  methods: {
    quit() {
      this.$refs.form.resetFields();
      this.$router.go(-1);
    },
    async save(form) {
      let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let code = this.form.code[0] - 0;
      let isNumber = false;
      for (let i = 0; i < arr.length; i++) {
        if (code === arr[i]) {
          isNumber = true;
        }
      }
      if (isNumber) {
        this.$message.warning('代码不能以数字开头');
        return false;
      }
      this.$refs[form].validate(async valid => {
        if (valid) {
          const { groupId, name, code, iconUrl, iconId } = this.form;
          const data = {
            groupId,
            name,
            code,
            iconId,
            iconUrl
          };
          const { fail } = await this.$apis.basicParamsGroup.save(data);
          if (!fail) {
            this.$router.go(-1);
          }
        } else {
          this.$message.error('请正确填写参数信息');
          return false;
        }
      });
    },
    async edit(form) {
      let arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      let code = this.form.code[0] - 0;
      let isNumber = false;
      for (let i = 0; i < arr.length; i++) {
        if (code === arr[i]) {
          isNumber = true;
        }
      }
      if (isNumber) {
        this.$message.warning('代码不能以数字开头');
        return false;
      }
      this.$refs[form].validate(async valid => {
        if (valid) {
          const res = await this.$apis.basicParamsGroup.previewCite({ groupId: this.form.groupId });
          if (res.deviceTemplateVoList.length === 0 || res.deviceParamGroupVoList === 0) {
            this.update();
          } else {
            this.TemplateData = res.deviceTemplateVoList;
            this.NestGroupData = res.deviceParamGroupVoList;
            this.drawer = true;
          }
        } else {
          this.$message.error('请正确填写参数信息');
          return false;
        }
      });
    },
    closeDrawer() {
      this.drawer = false;
    },
    async update() {
      const { groupId, name, code, iconId, iconUrl } = this.form;
      const data = {
        groupId,
        name,
        code,
        iconId,
        iconUrl
      };
      const { fail } = await this.$apis.basicParamsGroup.update(data);
      if (!fail) {
        this.$router.go(-1);
      }
    }
  }
};
</script>
<style>
.el-row {
  margin-bottom: 20px;
}
.basicParams {
  overflow: hidden;
}
.el-input,
.el-select {
  width: 100%;
}
</style>
