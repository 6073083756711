<template lang="html">
  <div>
    <!-- 图片预览/选择图片 -->
    <el-button class="upload-btn" @click="open()" :disabled="disabled">
      <el-image class="btn-image" :src="iconUrl ? baseip + iconUrl : setimgUrl !== '' ? (setimgUrl.indexOf(baseip) != -1 ? setimgUrl : baseip + setimgUrl) : baseip + defaultUrl"> </el-image>
      <img src="../img/icon_bg_img.png" class="bgimg" />
    </el-button>
    <!-- 选择弹窗 -->
    <el-dialog title="图片选择" :visible.sync="isOpen" width="55%" :before-close="handleClose" :close-on-click-modal="false" append-to-body>
      <div class="dialog_box">
        <div class="filetype" @keyup.enter="handleIconClick">
          <el-input v-model="keyword" placeholder="图片名称" style="margin-bottom: 10px" suffix-icon="el-icon-search">
            <!-- <i class="el-icon-search" slot="suffix" @click="handleIconClick"> </i> -->
          </el-input>
          <div class="typeinfo" :class="item.picTypeId == picTypeId ? 'fileActive' : ''" v-for="item in filetype" :key="item.picTypeId" @click="clickfile(item)">
            <img src="../img/wj_icon_img.png" />
            <span class="fileName" :title="item.name">{{ item.name }}</span>
          </div>
        </div>
        <div class="imginfo">
          <div class="imgModel">
            <div class="imgbox" v-for="item in isSearch ? searchImgList : imgList" :key="item.id">
              <div class="img" :class="item.id == iconId ? 'imgActive' : ''" @click="checkImg(item)">
                <img :src="baseip + item.picPath + item.fileName" />
              </div>
              <div class="name" @dblclick.stop="renName(item)">{{ item.name }}</div>
            </div>
          </div>
          <div class="btnModel">
            <el-row>
              <el-col :span="4">
                <el-button icon="el-icon-upload2" @click="openUpload()">本地上传</el-button>
              </el-col>
              <el-col :offset="10" :span="8" class="btnGroup">
                <el-button @click="handleClose()">取消</el-button>
                <el-button type="primary" @click="enterImg()">确定</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 上传弹窗 -->
    <el-dialog title="上传图片" :visible.sync="isupload" width="405px" :close-on-click-modal="false" append-to-body>
      <el-upload
        drag
        accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
        action=""
        :file-list="fileList"
        :on-change="changeFile"
        :auto-upload="false"
        :on-remove="handleRemove"
        :multiple="false"
        :limit="2"
      >
        <div style="font-weight: bolder; font-size: 16px; margin-bottom: 20px; margin-top: 40px">拖拽图片到本区域上传</div>
        <div class="el-upload__text" style="margin-bottom: 20px">或者<em>点击这里</em>上传</div>
        <div class="el-upload__tip">图片类型仅限JPG、GIF、BMP、PNG，图片大小不得超过500K</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.stop="uploadFile" size="mini" :disabled="fileList.length === 0 ? true : false">上传</el-button>
        <el-button @click="closeUploadFile" size="mini">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 重命名框 -->
    <el-dialog title="重命名" :visible.sync="isUpdateName" width="30%" :close-on-click-modal="false" append-to-body>
      <el-form-item label="图片名称">
        <el-col :span="20">
          <el-input v-model="renNamefrom.name"></el-input>
        </el-col>
      </el-form-item>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeUpdateName()">取 消</el-button>
        <el-button type="primary" @click="updateName()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/***
 * @upload组件
 * 可接受数据
 * @setimgUrl         按钮图片Url
 * @currenttype       类型   /  1 - 未分类, 2 - 设备图片, 3 - 模板图片, 4 - 参数图片, 5 - 参数组图片, 6 - 类型图片, 7 - 工厂图片, 8 - 车间图片,  9 -产线图片 , 11- 看板设备
 * @disabled          是否可修改
 * 可监听事件
 * @getimgUrl         获取选中图片拼接后iconUrl
 * @geticonId         获取选中图片id
 */
export default {
  name: 'uploadimg',
  props: {
    setimgUrl: {
      type: String
    },
    currenttype: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      defaultIcon: {
        line: 'ecm/holdhope/2021-11-02/line.png',
        workShop: 'ecm/holdhope/2021-11-02/workshop.png',
        factory: 'ecm/holdhope/2021-11-02/factory.png',
        paramsGroup: 'ecm/holdhope/2021-11-02/param_group.png',
        device: 'ecm/holdhope/2021-11-02/device.png',
        template: 'ecm/holdhope/2021-11-02/template.png',
        type: 'ecm/holdhope/2021-11-02/device_type.png',
        params: 'ecm/holdhope/2021-11-02/default.png',
        pane: 'ecm/holdhope/2021-11-02/moxing.png'
      },
      iconUrl: '',
      keyword: '',
      isOpen: false,
      baseip: '',
      fileList: [],
      filetype: [],
      iconId: '',
      searchImgList: [],
      imgList: [],
      picTypeId: 1,
      isupload: false,
      renNamefrom: {
        name: '',
        picId: ''
      },
      isUpdateName: false,
      isSearch: false,
      defaultUrl: ``
    };
  },

  created() {
    this.changeDefault();
    switch (this.currenttype) {
      case 2:
        this.iconId = 4;
        break;
      case 3:
        this.iconId = 3;
        break;
      case 4:
        this.iconId = 1;
        break;
      case 5:
        this.iconId = 5;
        break;
      case 6:
        this.iconId = 2;
        break;
      case 7:
        this.iconId = 6;
        break;
      case 8:
        this.iconId = 7;
        break;
      case 9:
        this.iconId = 8;
        break;
      case 11:
        this.iconId = 9;
        break;
    }
    this.baseip = process.env.VUE_APP_ICONIMG_URL;
    if (this.setimgUrl) {
      return false;
    } else {
      this.$emit('getimgUrl', this.defaultUrl);
      this.$emit('geticonId', this.iconId);
    }
  },
  watch: {
    currenttype() {
      this.changeDefault();
      this.$emit('getimgUrl', this.defaultUrl);
      this.$emit('geticonId', this.iconId);
    }
  },
  methods: {
    changeDefault() {
      this.defaultUrl = ``;
      switch (this.currenttype) {
        case 2:
          this.defaultUrl = this.defaultIcon.device;
          break;
        case 3:
          this.defaultUrl = this.defaultIcon.template;
          break;
        case 4:
          this.defaultUrl = this.defaultIcon.params;
          break;
        case 5:
          this.defaultUrl = this.defaultIcon.paramsGroup;
          break;
        case 6:
          this.defaultUrl = this.defaultIcon.type;
          break;
        case 7:
          this.defaultUrl = this.defaultIcon.factory;
          break;
        case 8:
          this.defaultUrl = this.defaultIcon.workShop;
          break;
        case 9:
          this.defaultUrl = this.defaultIcon.line;
          break;
        case 11:
          this.defaultUrl = this.defaultIcon.pane;
          break;
      }
    },
    handleIconClick() {
      if (this.keyword !== '') {
        let list = [];
        // eslint-disable-next-line consistent-this
        let _this = this;
        this.imgList.map(function (item) {
          if (item.name.indexOf(_this.keyword) > -1) {
            return list.push(item);
          }
        });
        this.isSearch = true;
        this.searchImgList = list;
      } else {
        this.isSearch = false;
        this.searchImgList = [];
      }
    },
    async open() {
      this.isOpen = true;
      this.picTypeId = this.currenttype;
      const res = await this.$apis.imgModel.listPicType({ picTypeId: this.currenttype });
      this.filetype = res;
      this.getimgList();
    },
    async getimgList(item = {}) {
      const picTypeId = item.picTypeId || this.picTypeId;
      const res = await this.$apis.imgModel.imgList({ picTypeId });
      this.imgList = res;
    },
    changeFile(file) {
      this.fileList = [];
      this.fileList.push(file);
    },
    handleRemove() {
      this.file = [];
      // this.fileList =
    },
    async uploadFile() {
      const picTypeId = this.picTypeId;
      const file = this.fileList[0].raw;
      const { fail } = await this.$apis.imgModel.upload({ picTypeId, file });
      if (fail) {
        // eslint-disable-next-line no-useless-return
        return;
      }
      this.fileList = [];
      this.isupload = false;
      this.getimgList();
    },
    async getFileList() {
      const fileRes = await this.$apis.imgModel.fileList();
      this.fileData = fileRes;
    },
    closeUploadFile() {
      this.fileList = [];
      this.fileList.length = 0;
      this.isupload = false;
    },
    checkImg(item) {
      this.iconId = item.id;
      this.$forceUpdate();
    },
    clickfile(item) {
      this.iconId = '';
      this.picTypeId = item.picTypeId;
      this.$forceUpdate();
      this.getimgList(item);
    },
    handleClose() {
      this.iconId = '';
      this.iconUrl = '';
      this.defaultUrl = '';
      this.filetype = [];
      this.isOpen = false;
    },
    async updateName() {
      const data = this.renNamefrom;
      const { fail } = await this.$apis.imgModel.updataName(data);
      if (fail) {
        // eslint-disable-next-line no-useless-return
        return;
      }
      this.closeUpdateName();
    },
    closeUpdateName() {
      this.isUpdateName = false;
      this.getimgList();
    },
    enterImg() {
      if (this.iconId === '') {
        this.$message.warning('未选择素材');
      } else {
        this.imgList.forEach(item => {
          if (item.id === this.iconId) {
            this.iconUrl = `${item.picPath + item.fileName}`;
            this.$emit('getimgUrl', this.iconUrl);
            this.$emit('geticonId', this.iconId);
            this.handleClose();
          }
        });
      }
    },
    renName(item) {
      const { name, id } = item;
      this.renNamefrom = {
        name,
        picId: id
      };
      this.isUpdateName = true;
    },
    openUpload() {
      this.isupload = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../css/index.scss';
</style>
