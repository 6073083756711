<template>
  <div class="base-section">
    <div class="section">
      <p class="name">
        <span>{{ name }}</span>
      </p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
@import '@/common/style/var';
.section {
  .name {
    font-size: 16px;
    position: relative;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 6px;
    margin-bottom: 20px;
    span {
      font-family: stand;
      border-bottom: 2px solid $--color-primary;
      padding-bottom: 8px;
    }
  }
}
</style>
